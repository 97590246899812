<script>
import Layout from "../layouts/main";
import Banner from "../../components/settings/banner.vue";
import Contact from "../../components/settings/contact.vue";

export default {
  components: { Layout, Banner,Contact},
};
</script>

<template>
  <Layout>
    <b-tabs content-class="mt-3" style="background-color:#fff">
      <b-tab title="Banner" active> <Banner /></b-tab>
      <b-tab title="Contact"> <Contact/></b-tab>
    </b-tabs>
  </Layout>
</template>
