<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import BannerServiceApi from "@/service/api/banner.js";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import Spinner from "@/components/spinner";

export default {
  data() {
    return {
      banner: null,
      banners: [],
      showBanners: false,
      loading: false,

      primaryOptions: {
        gap: "0rem",
        pagination: false,

        heightRatio: 0.5,
      },
      secondaryOptions: {
        type: "slide",
        rewind: true,
        gap: "1rem",
        pagination: false,
        fixedWidth: 110,
        fixedHeight: 70,
        cover: true,
        isNavigation: true,
        updateOnMove: true,
      },
      count: 0,
      banner_update: null,
      selected_banner: null,

      status: {
        deleting: false,
        updating: false,
        creating: false,
      },
    };
  },
  components: {
    Splide,
    SplideSlide,
    Spinner,
  },
  mounted() {
    this.getAllBanner();
  },
  methods: {
    submitBannerForm() {
      if (
        this.banner &&
        this.banner.files &&
        this.banner.files[0] &&
        this.banner.files[0].file
      ) {
        let formData = new FormData();
        formData.append("image_path", this.banner.files[0].file);
        formData.append("action", "add");
        this.status.creating = true;
        BannerServiceApi.bannerAction(formData)
          .then((response) => {
            if (response.data.success) {
              this.$refs["modalBannerUpload"].hide();
              this.banners = [];
              this.bannerActionMessage(
                "Banner Action",
                "Banner added successfully!"
              );
              this.getAllBanner();
            }
          })
          .catch(() => {
            this.bannerActionMessage(
              "Banner Action",
              `Banner addition failed!`,
              "danger"
            );
          })
          .finally(() => {
            this.status.creating = false;
          });
      } else {
        console.log("No image");
      }
    },
    getAllBanner() {
      this.loading = true;
      BannerServiceApi.getAllBanner()
        .then((response) => {
          this.banners = response.data.banners;
          this.showBanners = true;
        })
        .finally(() => {
          this.loading = false;
           this.$refs.primary.sync(this.$refs.secondary.splide);
        });
    },
    editBanner(banner) {
      this.selected_banner = banner;
      this.$refs["modalBannerEdit"].show();
    },
    deleteBannerConfirm() {
      if (this.selected_banner && this.selected_banner.id) {
        let formData = new FormData();
        formData.append("action", "delete");
        formData.append("banner_id", this.selected_banner.id);
        this.status.deleting = true;
        BannerServiceApi.bannerAction(formData)
          .then((response) => {
            if (response.data.success) {
              this.$refs["modalBannerEdit"].hide();
              console.log(response.data);
              this.bannerActionMessage(
                "Banner Action",
                "Banner deleted successfully!"
              );
            }
          })
          .catch(() => {
            this.bannerActionMessage(
              "Banner Action",
              `Banner deletion failed!`,
              "danger"
            );
          })
          .finally(() => {
            this.banners = [];
            this.status.deleting = false;
            this.$refs["modalDeleteConfirm"].hide();

            this.getAllBanner();
          });
      }
    },
    updateBannerForm(id) {
      if (
        this.banner_update &&
        this.banner_update.files &&
        this.banner_update.files[0] &&
        this.banner_update.files[0].file
      ) {
        let formData = new FormData();
        formData.append("image_path", this.banner_update.files[0].file);
        formData.append("action", "edit");
        formData.append("banner_id", id);
        this.status.updating = true;
        BannerServiceApi.bannerAction(formData)
          .then((response) => {
            if (response.data.success) {
              this.$refs["modalBannerEdit"].hide();
              this.banners = [];
              this.getAllBanner();
              this.bannerActionMessage(
                "Banner Action",
                "Banner updated successfully!"
              );
            }
          })
          .catch(() => {
            this.bannerActionMessage(
              "Banner Action",
              "Banner update failed!",
              "danger"
            );
          })
          .finally(() => {
            this.status.updating = false;
          });
      } else {
        console.log("not edit");
      }
    },
    deleteBanner() {
      this.$refs["modalBannerEdit"].hide();
      this.$refs["modalDeleteConfirm"].show();
    },
    bannerActionMessage(title, message, variant = "success") {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-banner-upload"
      ref="modalBannerUpload"
      title="Add New Banner"
      hide-footer
    >
      <FormulateInput
        type="image"
        v-model="banner"
        label="Select a banner to upload"
        help="Select a png, jpg or gif to upload."
        validation="required|mime:image/jpeg,image/png,image/gif"
        error-behavior="live"
      />
      <button class="btn btn-danger float-right" @click="submitBannerForm">
        <span v-if="!status.creating">Upload</span>
        <span v-else>Uploading</span>
      </button>
    </b-modal>
    <b-modal
      ref="modalDeleteConfirm"
      id="modal-delete-confirm"
      title="Confirm your Deletion"
      hide-footer
    >
      <div style="margin:28px 0px; text-align:center;font-size:16px">
        Are you sure you want to delete this item?
      </div>
      <button
        class="btn btn-primary"
        @click="
          () => {
            this.$refs['modalDeleteConfirm'].hide();
          }
        "
      >
        Cancel
      </button>
      <button class="btn btn-danger float-right" @click="deleteBannerConfirm">
        <span v-if="!status.deleting">Confirm</span>
        <span v-else>Deleting</span>
      </button>
    </b-modal>
    <b-modal
      id="modal-banner-edit"
      ref="modalBannerEdit"
      title="Edit Banner"
      hide-footer
    >
      <div v-if="selected_banner">
        <img
          :src="selected_banner.image"
          class="img-fluid lazyload"
          style="height:240px;width:100%;border:1px solid #f1f1f1"
          alt=""
        />
      </div>
      <FormulateInput
        type="image"
        v-model="banner_update"
        label="Select a banner to update"
        help="Select a png, jpg or gif to upload."
        validation="mime:image/jpeg,image/png,image/gif"
        error-behavior="live"
      />
      <button class="btn btn-danger" @click="deleteBanner">
        Delete
      </button>
      <button
        class="btn btn-warning float-right"
        @click="updateBannerForm(selected_banner.id)"
      >
        <span v-if="!status.updating">Update</span>
        <span v-else>Updating</span>
      </button>
    </b-modal>
    <button
      class="btn btn-md btn-primary float-right mr-3"
      style="width:100px"
      v-b-modal.modal-banner-upload
    >
      Add Slides
    </button>
    <div class="clearfix"></div>
    <div v-if="loading">
      <Spinner :show="loading" />
    </div>
    <div>
      <div class=" mt-1 p-5" v-if="showBanners && banners.length">
        <div class="container-fluid  mb-5" style="max-width:820px;margin:auto;overflow:hidden">
          <splide :options="primaryOptions" ref="primary">
            <splide-slide v-for="banner in banners" :key="banner.id">
              <img :src="banner.image" alt="slide.alt" style="width:100%;" />
            </splide-slide>
          </splide>

          <div style="padding-top:10px">
            <splide :options="secondaryOptions" ref="secondary">
            <splide-slide v-for="banner in banners" :key="banner.id">
              <img :src="banner.image" alt="slide.alt" />
              <button
                class="btn btn-danger btn-sm float-right mt-1 mr-1"
                style="height:24px;padding-top:0px;width:24px;padding-left:4px"
                @click="editBanner(banner)"
              >
                <i class="mdi mdi-pencil font-size-14"></i>
              </button>
            </splide-slide>
          </splide>
          </div>
       
        </div>
      </div>
      <div
        v-else
        style="font-size:18px;font-weight:800;text-align:center;padding:100px"
      >
        No Banners
      </div>
    </div>
  </div>
</template>

<style>
.splide__arrow svg {
  fill: #e04545;
}
.splide__arrow:hover svg {
  fill: #ce3535;
}
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: #e04545;
}
</style>
