import axios from "@/plugins/axios";
//import authHeader from '../auth-header'
import ConfigService from "../config"
import authHeaderMultipart from "../auth-header-multipart" 

const API_URL = ConfigService.base_url;

class BannerServiceApi {
    getAllBanner() {
        return axios
          .get(API_URL + 'banners')
          .then(response => {
            console.log(response.data);
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      bannerAction(banner){
        return axios
        .post(API_URL + 'admin/banner', banner,
        {
          headers: authHeaderMultipart()
        })
        .then(response => {
          return response;
        })
        .catch(error=> {
            return Promise.reject(error);
          }
        )
      }
}



export default new BannerServiceApi()