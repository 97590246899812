<script>
import Spinner from "@/components/spinner";
import ContactServiceApi from "@/service/api/contact"

export default {
  data() {
    return {
      edit:false,
      updating: false,
      contact:{
        address: "",
        contact:""
      },

    };
  },
  components: {
    Spinner,
  },
  mounted() {
    this.getContact()
  },
  methods: {
    submitHandler(data) {
      this.updateContact(data);
 
    },
    async getContact(){
        await ContactServiceApi.contact({action:"get"}).then((response)=>{
          this.contact.address = response.data.office.address;
          this.contact.contact = response.data.office.contact;
        })
    },
    async updateContact(data){
      this.updating  = true;
      await ContactServiceApi.contact(data).then((response)=>{
        console.log(response.data)
        this.updating =false;
        this.edit= false;
        this.ToastMessage({title:"Contact update",body:"Contact Updated successfully!"})
      }).catch((error)=>{
        console.log(error);
        this.ToastMessage({title:"Contact update",body:"Contact updation failed!"},"danger")
      })
    },
    enableEdit(){
      this.edit=  true;
    }
  },
};
</script>

<template>
  <div class="p-5">

    <Spinner />
    <FormulateForm @submit="submitHandler" class="pb-5">
      <FormulateInput
        type="text"
        name="contact"
        v-model="contact.contact"
        label="Contact"
        validation="required"
      />
      <FormulateInput
        type="textarea"
        name="address"
         v-model="contact.address"
        label="Address"
        validation="required"
      />
      <div class="contact-update" style="display:inline-block" > 
       <FormulateInput type="button" @click="enableEdit" label="Edit Contact" />
      </div>

     
      <FormulateInput v-if="edit" class="float-right" type="submit" :label="this.updating?'Saving Details...':'Save Details'" />
    </FormulateForm>
  </div>
</template>

<style>
 .contact-update .formulate-input[data-classification=button] button{
  background-color:#fcb92c  !important;
  border:none !important;
}
</style>
